import React from "react";
import "./StepLoader.css";

const StepLoader = ({ currentStep = 1 }) => {
//   const steps = [
//     { step: "Validate Passport", description: "Verify passport details, including passport number, expiration date, and full name.", icon: "🔑" },
//     { step: "Bank Details", description: "Validate bank details provided by the user to ensure they are accurate.", icon: "💳" },
//     { step: "Documents", description: "Check and validate all user-uploaded documents like certificates, insurance documents, etc.", icon: "📄" },
//     { step: "Face Match", description: "Compare the user’s facial image with the passport image to ensure they are the same person.", icon: "🖼️" },
//   ];

const steps = [
    { 
      step: "Validate Passport", 
      description: "Verify passport details, including passport number, expiration date, and full name.", 
      icon: "🛂"  // Passport control icon
    },
    { 
      step: "Bank Details", 
      description: "Validate bank details provided by the user to ensure they are accurate.", 
      icon: "🏦"  // Bank building icon
    },
    { 
      step: "Documents", 
      description: "Check and validate all user-uploaded documents like certificates, insurance documents, etc.", 
      icon: "📑"  // Stack of documents icon
    },
    { 
      step: "Face Match", 
      description: "Compare the user’s facial image with the passport image to ensure they are the same person.", 
      icon: "🪪"  // Identification card icon
    },
  ];
  

  const getStepColor = (stepId) => {
    switch (stepId) {
      case 1:
        return currentStep === 1 ? "#4CAF50" : currentStep > 1 ? "#4CAF50" : "#bbb";
      case 2:
        return currentStep === 2 ? "#4CAF50" : currentStep > 2 ? "#4CAF50" : "#bbb";
      case 3:
        return currentStep === 3 ? "#4CAF50" : currentStep > 3 ? "#4CAF50" : "#bbb";
      case 4:
        return currentStep === 4 ? "#4CAF50" : currentStep > 4 ? "#4CAF50" : "#bbb";
      default:
        return "#bbb";
    }
  };

  return (
    <div className="step-loader-container">
      <div className="step-indicator">
        {steps.map((step, index) => (
          <div key={index} className="step">
            <div
              className={`circle ${currentStep === index + 1 ? "highlighted-step" : ""} ${
                currentStep > index + 1 ? "completed" : ""
              }`}
              style={{ backgroundColor: getStepColor(index + 1) }}
            >
              <span className="icon">{step.icon}</span>
            </div>
            {index < steps.length - 1 && (
              <div
                className={`step-line ${currentStep > index + 1 ? "line-completed" : ""}`}
                style={{
                  backgroundColor: currentStep > index + 1 ? getStepColor(index + 1) : "#bbb",
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
      <div className="step-info">
        {/* Dynamically display description based on the current step */}
        <h3>{steps[currentStep - 1]?.step}</h3>
        <p>{steps[currentStep - 1]?.description}</p>
      </div>
    </div>
  );
};

export default StepLoader;
