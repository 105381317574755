import React, { useState, useEffect, useRef, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/boar-icon.svg";
import bank_verified from "../../assets/images/sailor/verified.png"
import Ham from "../../assets/images/sailor/ham.svg";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import tick from "../../assets/images/sailor/tick.png"
import passport_img from "../../assets/images/sailor/pass.jpg"
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormHelperText } from '@mui/material';
import SideMenu from "../../components/SideMenu";
// import NoteContext from "../../context/NoteContext";
import Navbar from "../Navbar";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import axios from 'axios';
import Moment from 'react-moment';
import { config } from "../../util/apiconfig";


function Viewapplication() {

  const [sailor_info, setsailor_info] = useState([])
  const [sailor_certificateinfo, setsailor_certificateinfo] = useState([])

  const [Agree, setAgree] = useState('')
  const [links, setLinks] = useState(false)

  const [check, setCheck] = useState(false)
  const [img, setImg] = useState()

  useEffect(() => {
    if (!check) {
      fetchData();
      fetchPhoto();
      setCheck(true);
    }
  })


  async function fetchData() {
    let data = await fetch(config.mongo_endpoint+"api/v1/sailorUser/viewApplication", {
      method: "GET",
      headers: {
      "Content-type": "application/json",
      "x-access-token" : `${localStorage.getItem('Token')}`

      }
    });
    let res = await data.json();
    if (res.status === "Success") {
      // obj.setToken(res.data[0].token)
      console.log("application",res)
      setsailor_info(res.data[0])
      setsailor_certificateinfo(JSON.parse(res.data[0].Certificates[0]))
      console.log('res.data[0].Certificates[0] > ',res.data[0].Certificates[0])

    } else {
      alert(res.message);

    }
  }
  // async function fetchPhoto() {
  //   let photo = await fetch(config.mongo_endpoint+"api/v1/sailorUser/viewPhoto", {
  //     method: "GET",
  //     headers: {
  //     "Content-type": "application/json",
  //     "x-access-token" : `${localStorage.getItem('Token')}`

  //     }
  //   });
  //   console.log(photo)
  //   // let res = photo.data;
  //   // console.log(res)
  //   // setImg(config.imagePath+res);
  // }
  const fetchPhoto = async() => {
    
   
    await axios.get(config.mongo_endpoint+"api/v1/sailorUser/viewPhoto", { headers: { "Content-type": "application/json",
        "x-access-token" : `${localStorage.getItem('Token')}`} })
                  .then(result => {
                    console.log("photo",result.data);
                    if(result.status == 200){
                      setImg(config.imagePath+result.data);
                      
                    } 
                  })
                 
                  .catch(error => {
                    // handle the error
                    console.error(error);
                    if (error) {
                      
                      // toast.error("Something went wrong. Please try again Later....", {
                      // position: toast.POSITION.TOP_CENTER,
                      // theme: "colored",
                    // });
            
                    }
                  
                  });

   
    
  }


 

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,

    },
  }));



  return (
    <>

      <SideMenu></SideMenu>

      <div className="container-fluid pr-0" id="main-area">
        <Navbar />
        <div className="container-fluid">
          <div className="d-flex border-bottom pb-15 pt-15">
            <div className="me-auto ">
              <h2 className="page-title">View Application</h2>
             

            </div>

          </div>
          <section className="voting-section">
            <div className="container">
              <div className="row">
                <div className="">
                  <Box mb={3.5}>
                    <Grid container spacing={3}>
                      <Grid item sm={6} xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Personal Information</StyledTableCell>
                                <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow sx={{ height: '50%' }}>
                                  <StyledTableCell component="th" scope="row">
                                    First Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.First_Name ? sailor_info.First_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Middle Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.middle_name ? sailor_info.middle_name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Last Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Last_Name ? sailor_info.Last_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Date of Birth
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Date_of_Birth ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Date_of_Birth}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Place of Birth
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Place_of_Birth ? sailor_info.Place_of_Birth : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Marital Status
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Maritial_Status ? sailor_info.Maritial_Status : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Country
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Country ? sailor_info.Country : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Nationality
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Nationality ? sailor_info.Nationality : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Mobile No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Phone ? sailor_info.Phone : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Address of Communication
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Corresponding_Address ? sailor_info.Corresponding_Address : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Permanent Address
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Permanent_Address ? sailor_info.Permanent_Address : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Emergency Contact Person
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Emergency_Contact_Person ? sailor_info.Emergency_Contact_Person : ""}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Emergency Contact Number
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Emergency_Contact_Number ? sailor_info.Emergency_Contact_Number : ""}</StyledTableCell>

                                </StyledTableRow>

                              </TableBody>
                          </Table>
                        </TableContainer>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Passport Info</StyledTableCell>
                                <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Passport No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Passport_Number ? sailor_info.Passport_Number : ""} </StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Place of Issue
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Place_Of_Issue ? sailor_info.Place_Of_Issue : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Date Of Issue
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Date_Of_Issue ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Date_Of_Issue}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                  Valid Through
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Passport_Valid_Through ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Passport_Valid_Through}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>

                                
                                
                              </TableBody>
                          </Table>
                        </TableContainer>
                        <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Bank Details</StyledTableCell>
                                  <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account Holder Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Account_Name ? sailor_info.Account_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Account_Number ? sailor_info.Account_Number : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    IFSC Code
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.IFSC_Code ? sailor_info.IFSC_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    SWIFT Code
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.SWIFT_Code ? sailor_info.SWIFT_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Branch Address
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Branch_Code ? sailor_info.Branch_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        
                        
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Experience Details</StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Position Held
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info.Last_Position_held ? sailor_info.Last_Position_held : ""}</StyledTableCell>

                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Year of Experience at last held
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info.Years_Of_Experience ? sailor_info.Years_Of_Experience : ""}</StyledTableCell>

                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                            Position Applying For
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info.Position_Applying_for ? sailor_info.Position_Applying_for : ""}</StyledTableCell>

                          </StyledTableRow>

                          


                          </TableBody>
                          </Table>
                        </TableContainer>
                         <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Documents</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>

                              </TableHead>
                              <TableBody>
                                
                                 {sailor_certificateinfo.map((resp, index) => (
                                

                                  <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                      {resp.Course_Name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right"> {resp.Issusing_Authority}</StyledTableCell>
                                    <StyledTableCell align="right">{resp.Certificate_Valid_Till ? <Moment format="DD/MM/YYYY">
                                  {resp.Certificate_Valid_Till}
                                  </Moment> : ""}

                                    </StyledTableCell>
                                    <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>

                                  </StyledTableRow>
                                 ))} 
                              </TableBody>
                            </Table>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell colSpan={3}>Verification Status</StyledTableCell>

                              </TableRow>

                            </TableHead>
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  <img src={img} style={{ width: '100px' }} />
                                </StyledTableCell>
                                <StyledTableCell align="center"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                                {/* <StyledTableCell align="right"> <img src={img} style={{ width: '100px' }} /></StyledTableCell> */}

                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell align="center" colSpan={3}>
                                  {/* Face Match Alert */}
                                  <Alert
                                    severity="success"
                                    className="alert-message"
                                    sx={{
                                      fontSize: "18px",
                                      width: "100%",
                                      color: "#2e7d32",
                                      border: "1px solid #2e7d32",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      marginBottom: "12px",
                                      backgroundColor: "#e8f5e9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <strong>Face Match:</strong> {sailor_info.Face_match_per}%
                                    </div>
                                  </Alert>

                                  {/* Passport Match Alert */}
                                  <Alert
                                    severity="success"
                                    className="alert-message"
                                    sx={{
                                      fontSize: "18px",
                                      width: "100%",
                                      color: "#2e7d32",
                                      border: "1px solid #2e7d32",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      marginBottom: "12px",
                                      backgroundColor: "#e8f5e9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <strong>Passport Match Successful:</strong> {sailor_info.Passport_match_per}%
                                    </div>
                                    <div style={{ marginTop: "8px", textAlign: "left" }}>
                                      <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
                                        <li style={{ marginBottom: "4px" }}>•  Name: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Passport Number: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Validity: Confirmed</li>
                                      </ul>
                                    </div>
                                  </Alert>

                                  {/* Penny Check Match Alert */}
                                  <Alert
                                    severity="success"
                                    className="alert-message"
                                    sx={{
                                      fontSize: "18px",
                                      width: "100%",
                                      color: "#2e7d32",
                                      border: "1px solid #2e7d32",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      marginBottom: "12px",
                                      backgroundColor: "#e8f5e9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                    <strong>Bank Account Match Successful</strong>
                                    </div>
                                    <div style={{ marginTop: "8px", textAlign: "left" }}>
                                      <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
                                        <li style={{ marginBottom: "4px" }}>•  Name: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Account Number: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Penny Drop Verfication: Completed</li>
                                        <li style={{ marginBottom: "4px" }}>•  Cancelled Cheque: Verified</li>
                                      </ul>
                                    </div>
                                  </Alert>

                                  {/* Document Check and Certificate Number Check Alert */}
                                  <Alert
                                    severity="success"
                                    className="alert-message"
                                    sx={{
                                      fontSize: "18px",
                                      width: "100%",
                                      color: "#2e7d32",
                                      border: "1px solid #2e7d32",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      backgroundColor: "#e8f5e9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <strong>Document Check Successful</strong>
                                    </div>
                                    <div style={{ marginTop: "8px", textAlign: "left" }}>
                                      <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
                                        <li style={{ marginBottom: "4px" }}>•  Document Validity: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Certificate Number: Verified</li>
                                      </ul>
                                    </div>
                                  </Alert>
                                </StyledTableCell>
                              </StyledTableRow>




                            </TableBody>
                          </Table>
                        </TableContainer>




                      </Grid>
                      
                      
                    </Grid>
                  </Box>

                </div>


              </div>
            </div>
          </section>
        </div>
      </div>




    </>
  );
}

export default Viewapplication;