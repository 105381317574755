import React, { useState, useEffect,useContext } from "react";
import Topnav from "../../common/topNav";
import { Link  } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";

import Ham from "../../assets/images/sailor/ham.svg";

import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper } from '@mui/material';

import AgentMenu from "../../components/AgentMenu";
import NoteContext  from "../../context/NoteContext";
import Navbar from "../Navbar";
import { config } from "../../util/apiconfig";


function Pendingonboarding() {

    const obj = useContext(NoteContext)
   
    const [data , setData] = useState([]);
     const fetchData = (row) => {        
          obj.setsailor_info(row)
          obj.setonboardingFlag(2);
    }

    async function getPendingdOnboardingList(){
        let requestData = {
            "Application_Status": "Pending"
        };

        let data = await fetch(config.mongo_endpoint+"api/v1/application/report", {
            method: "POST",
            headers: {
            "Content-type": "application/json",
            "x-access-token" : `${obj.token}`
            },
            body: JSON.stringify(requestData) 
        });
        let res = await data.json();
        console.log(res)
        if (res.status === "Success") {
            
            setData(res.data)
            console.log('res.data > ',res.data[0].Certificates)
        } else {
            alert(res.message);
    
        }
        
    }
    useEffect(()=>{
        getPendingdOnboardingList()
    },[])

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">
                <Navbar/>
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Pending Onboarding </h2>
                        </div>

                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ background: '#ddd' }}>S.No</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Onboarding ID</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>First Name</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Middle Name</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Last Name</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Country</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Phone No</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Last Position Held</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Total Experience</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Status</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>



                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row,index) => (
                                     
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" style={{ padding: '5px 15px' }}>
                                            {index+1}
                                        </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.onBoardingID}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.First_Name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Middle_Name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Last_Name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Country}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Phone}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Last_Position_held}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Years_Of_Experience}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Application_Status}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>
                                        
                                        <Link to='/ViewOnboard' onClick={()=>{fetchData(row)}}>View </Link> 
                                        </TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>





        </>
    );
}

export default Pendingonboarding;