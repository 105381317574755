import React, { useState, useEffect, useContext } from "react";
import SideMenu from "../../components/SideMenu";
// import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NoteContext from "../../context/NoteContext";
import Navbar from "../Navbar";
import { Box, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataArrayTwoTone } from "@mui/icons-material";
import { SearchBox } from "@mapbox/search-js-react";

function AddSailorhistory() {

    // const [status, setStatus] = React.useState('');
    const [formData, setFormData] = useState({})
    const [data, setData] = useState([])

    // const handleChange = (event) => {
    //     setStatus(value);
    // };
    const navigate = new useNavigate();
   const obj = useContext(NoteContext)
  //  if(obj.sailor_info.length === 0){
  //   if(obj.onboardingFlag === ""){
  //     navigate(-1)
  //   }
  //  }

   const [sailor_info, setsailor_info] = useState(obj.sailor_info)
   console.log("SAILORINFO", sailor_info)

   
   const sailorInformation = yup.object().shape({
    sailor_from: yup.string().required("Sailor From Required"),
    sailor_to: yup.string().required("Sailor To Required"),
    sailor_type: yup.string().required("Sailor Type Required"),
    imo_no: yup.string().required("IMoNo Required"),
    start_date: yup.string().required("Start Date Required"),
    end_date: yup.string().required("End Date Required"),
    status: yup.string().required("Status Required"),
  
  });

  const { register: sailorInfoHandler,
    handleSubmit: sailorInfoHandlerSubmit,
    reset: voterInfoReset,
    control,
    watch,
    setValue,
    formState: { errors: sailorInfoErrors } } = useForm({
      resolver: yupResolver(sailorInformation),
      mode: 'OnSubmit',
      
    });

    const handleDateChange = (formdate) => {
        if (formdate) {
          const date = new Date(formdate);
          // Format the date to YYYY-MM-DD
          const formattedDate = date.toLocaleDateString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          }).split('/').reverse().join('-');
          return formattedDate;
        }
       
      };

    // useEffect(()=>{
    //   setValue('sailor_from',sailor_info.sailorFrom);  
    // }, [])

    // async function findHis(){
    //   const response = {'_id': sailor_info._id}     
    //   let data = await fetch(config.mongo_endpoint+"api/v1/sailorUser/sailorHistory/findAll", response, {
    //       method: "GET",
    //       headers: {
    //       "Content-type": "application/json",
    //       "x-access-token" : `${localStorage.getItem('Token')}`
    //       }
    //   });
    //   let res = await data.json();
    //   if (res.status === "Success") {
    //       console.log("sailor_history",res.data)          
    //       const resResult = res.data;
    //       console.log(resResult)
    //       setData(resResult);     
    //         // const dataResult = resResult.filter((ress)=>{
    //         //     return ress._id === _id
    //         // })
    //         // console.log(dataResult)
          
    //   } else {
    //       alert(res.message);
  
    //   }    
      
      
  // }
  

    const onSubmit = async ( data ) => {
      const sailor_editinfo = {
        sailorFrom : data["sailor_from"],
        sailorTo : data["sailor_to"],
        shipType : data["sailor_type"],
        IMoNumber : data["imo_no"],
        endDate : handleDateChange(data["end_date"]),
        startDate : handleDateChange(data["start_date"]),
        status : data["status"],
        userId: localStorage.getItem('userId')
    }    
        await axios.post(config.mongo_endpoint+'api/v1/sailorUser/sailorHistory/add', sailor_editinfo, 
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded',
                     'x-access-token':localStorage.getItem('Token')} })
                    .then(result => {
                      
                      if(result.status == 200 && result.data.status == "Success"){
                        toast.success("Sailor History Added Successfully.", {
                          position: toast.POSITION.TOP_CENTER,
                          theme: "colored",
                        });  
                        navigate("/Sailorhistory");

                      } else {
                        toast.success("Some Thing went wrong try again later.", {
                          position: toast.POSITION.TOP_CENTER,
                          theme: "colored",
                        });  
                      }
                      
                      
                    })
                    .catch(error => {
                      // handle the error
                      console.error(error);
                      if (error) {
                        
                        toast.error("Some thing When wrong. Please try again Later....", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      });
              
                      }
                    
                    });

  
    } 


           
     
   

  return (
    <>
    <SideMenu></SideMenu>
    <div className="container-fluid pr-0" id="main-area">
<Navbar />
<div className="container-fluid">
<div className="d-flex border-bottom pb-15 pt-15">
    <div className="me-auto ">
        <h2 className="page-title">Add Sailor History</h2>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb  m-0">
                {/* <li className="breadcrumb-item"><a href="#">breadcrumb 01</a></li>
                <li className="breadcrumb-item"><a href="#">breadcrumb 02</a></li> */}
                {/* <li className="breadcrumb-item active" aria-current="page">Update Details </li> */}
            </ol>
        </nav>
    </div>

</div>
<section className="voting-section">
    <div className="container">
      <div className="row">

        {/* <h1>Voting</h1> */}
        <Box sx={{ width: '100%' }}>    
            <>
               <div className="stepper-content">
                
                  <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>                      
                  <Box mb={3.5}>
                    <Grid container spacing={3} marginTop={1}>
                    <Grid item sm={3} xs={12}>
                    <div className="search_ship_port">
                    <SearchBox
                      accessToken="pk.eyJ1Ijoia2FydGhpY3JlYXRvciIsImEiOiJjbHRjaDkwa20yM3JsMmtxcjc5Y3Rqc3NwIn0.4QgS7mxhfIDVjvwwctqBLg"
                      placeholder="Sailor From *"
                      style={{
                        boxSizing: 'unset',
                      }}
                          size="small"
                          fullWidth
                          type="text"
                          value={sailor_info.sailor_from}
                          name="sailor_from"
                          label="Sailor From *"
                          defaultValue={sailor_info.sailor_from}
                          onRetrieve={(result) => {
                            if (result && result?.features?.[0]?.properties?.name) {
                              setValue("sailor_from", result?.features?.[0]?.properties?.name);
                            }            
                          }}
                          {...sailorInfoHandler("sailor_from")}
                          id="outlined-error"
                          error={!!sailorInfoErrors['sailor_from']}
                          helperText={sailorInfoErrors['sailor_from'] ? sailorInfoErrors['sailor_from'].message : ''}
                        />
</div>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                    <div className="search_ship_port">
                    <SearchBox
                        accessToken="pk.eyJ1Ijoia2FydGhpY3JlYXRvciIsImEiOiJjbHRjaDkwa20yM3JsMmtxcjc5Y3Rqc3NwIn0.4QgS7mxhfIDVjvwwctqBLg"
                        placeholder="Sailor To *"
                        style={{
                          boxSizing: 'unset',
                        }}
                          size="small"
                          fullWidth
                          type="text"
                          value={formData.sailor_to}
                          name="sailor_to"
                          label="Sailor To *"
                          defaultValue={data.sailor_to}
                          onRetrieve={(result) => {
                            if (result && result?.features?.[0]?.properties?.name) {
                              setValue("sailor_to", result?.features?.[0]?.properties?.name);
                            }            
                          }}
                          {...sailorInfoHandler("sailor_to")}
                          id="outlined-error"
                          error={!!sailorInfoErrors['sailor_to']}
                          helperText={sailorInfoErrors['sailor_to'] ? sailorInfoErrors['sailor_to'].message : ''}
                        />
                        </div>
                    </Grid>
                    {/* <Grid item sm={3} xs={12}>
                    <TextField
                          size="small"
                          fullWidth
                          type="text"
                          value={formData.sailor_type}
                          defaultValue={data.sailor_type}
                          name="sailor_type"
                          label="Sailor Type *"
                          
                          {...sailorInfoHandler("sailor_type")}
                          id="outlined-error"
                          error={!!sailorInfoErrors['sailor_type']}
                          helperText={sailorInfoErrors['sailor_type'] ? sailorInfoErrors['sailor_type'].message : ''}
                        />
                    </Grid> */}

                    <Grid item sm={3} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="ship-type-label">Ship Type *</InputLabel>
                        <Select
                          labelId="ship-type-label"
                          id="ship-type"
                          name="sailor_type"
                          size="small"
                          value={formData.sailor_type} // Controlled value for ship_type
                          label="Ship Type *"
                          {...sailorInfoHandler("sailor_type")} // State handler for ship_type
                          error={!!sailorInfoErrors["sailor_type"]} // Error handling
                          onChange={(event) => {
                              setValue("sailor_type", event.target.value);
                          }}
                        >
                          <MenuItem value="Cargo">Cargo</MenuItem>
                          <MenuItem value="Passenger">Passenger</MenuItem>
                          <MenuItem value="Fishing">Fishing</MenuItem>
                          <MenuItem value="Tanker">Tanker</MenuItem>
                          <MenuItem value="Container">Container</MenuItem>
                        </Select>
                        {sailorInfoErrors.sailor_type && (
                          <FormHelperText error>
                            {typeof sailorInfoErrors["sailor_type"] === "string"
                              ? sailorInfoErrors["sailor_type"]
                              : sailorInfoErrors["sailor_type"]?.message || ""}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item sm={3} xs={12}>
                    <TextField
                          size="small"
                          fullWidth
                          type="text"
                          value={formData.imo_no}
                          defaultValue={data.imo_no}
                          name="imo_no"
                          label="IMo No *"
                          {...sailorInfoHandler("imo_no")}
                          id="outlined-error"
                          error={!!sailorInfoErrors['imo_no']}
                          helperText={sailorInfoErrors['imo_no'] ? sailorInfoErrors['imo_no'].message : ''}
                        />
                    </Grid>
                </Grid>
                 
                

                <Grid container spacing={3} marginTop={1}>
                
                    <Grid item sm={4} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                            <DatePicker
                              label="Start Date *"
                              value={formData.start_date}
                              defaultValue={data.start_date}
                              {...sailorInfoHandler("start_date")}
                              slotProps={{ textField: { size: 'small' } }}
                              onChange={(start_date) => {
                                setValue('start_date', start_date); 
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>

                        {sailorInfoErrors.start_date && <FormHelperText error={!!sailorInfoErrors['start_date']}>{sailorInfoErrors.start_date.message}</FormHelperText>}
                    </Grid>
                    <Grid item sm={4} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {console.log(sailor_info.end_date)}
                          <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                            <DatePicker
                              label="End Date *"
                              {...sailorInfoHandler("end_date")}
                              defaultValue={data.end_date}
                              value={formData.end_date}
                              slotProps={{ textField: { size: 'small' } }}
                              onChange={(end_date) => {
                                setValue('end_date', end_date); 
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>

                        {sailorInfoErrors.end_date && <FormHelperText error={!!sailorInfoErrors['end_date']}>{sailorInfoErrors.end_date.message}</FormHelperText>}
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={data.availability}
                                label="Status"
                                // onChange={handleChange}
                                {...sailorInfoHandler("status")}
                                value={formData.status}
                                
                                error={!!sailorInfoErrors['status']}
                                helperText={sailorInfoErrors['status'] ? sailorInfoErrors['status'].message : ''}
                            >
                                <MenuItem value="Available">Available</MenuItem>
                                <MenuItem value="On Duty">On Duty</MenuItem>
                                <MenuItem value="On Leave">On Leave</MenuItem>                                 
                            </Select>
                            {sailorInfoErrors.status && <FormHelperText error={!!sailorInfoErrors['status']}>{sailorInfoErrors.status.message}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    
                </Grid>   

                      
                  </Box>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </div>
              </form>                    
                 
                  {/* <ToastContainer /> */}
                </div>
             
              
            </>
      
        </Box>
      </div>
    </div>
  </section>

</div>
</div>
    </>
    );
}

export default AddSailorhistory;