import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner"; 
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/boar-icon.svg";

import Ham from "../../assets/images/sailor/ham.svg";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, TextField, Modal } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import tick from "../../assets/images/sailor/tick.png"
import passport_img from "../../assets/images/sailor/pass.jpg"
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormHelperText } from '@mui/material';
import AgentMenu from "../../components/AgentMenu";
import Navbar from "../Navbar";
import NoteContext  from "../../context/NoteContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useParams, useNavigate  } from 'react-router-dom';
import Moment from 'react-moment';
import bank_verified from "../../assets/images/sailor/verified.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Typography from '@mui/material/Typography';
import { SearchBox } from "@mapbox/search-js-react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

function Viewonboard() {
  var FormData = require('form-data');
  const obj = useContext(NoteContext)
  const navigate = new useNavigate ();
  console.log("dasd",obj);
  if(obj.sailor_info.length === 0){
    if(obj.onboardingFlag === ""){
      navigate(-1)
    }
  }
  const [sailor_info, setsailor_info] = useState(obj.sailor_info)
  const [img, setImg] = useState()

  const [applicationStatus, setApplicantStatus] = useState()
  const [ScreenView, setScreenView] = React.useState(obj.onboardingFlag);

  const [sailor_certificateinfo, setsailor_certificateinfo] = useState([]);

  useEffect(() => {
    if (
      Array.isArray(sailor_info.Certificates) &&
      typeof sailor_info.Certificates[0] === "string"
    ) {
      try {
        const parsedCertificates = JSON.parse(sailor_info.Certificates[0]);
        setsailor_certificateinfo(parsedCertificates);
      } catch (error) {
        console.error("Invalid JSON format:", error);
        setsailor_certificateinfo([]);
      }
    } else {
      setsailor_certificateinfo([]);
    }
  }, [sailor_info.Certificates]);


  const handleApplicationChange = (event) => {
    const { name, value } = event.target;
  setApplicantStatus((prevStatus) => ({
    ...prevStatus,
    [name]: value
  }));
  };

  // handleChange(event) {
  //   event.preventDefault();
  //   this.setState({
  //     [event.target.name]: event.target.value,
  //   });
  // }

  useEffect(() => {
      fetchPhoto();
      findHistory();

     
  },[])
  const fetchPhoto = async() => {
    const response = {'userId': sailor_info._id}
    await axios.post(config.mongo_endpoint+"api/v1/sailorUser/viewPhotoByOnBoardingID", response, { headers: {   'Content-Type': 'application/x-www-form-urlencoded',} })
                  .then(result => {
                    console.log("photo",result.data);
                    if(result.status == 200){
                      setImg(config.imagePath+result.data);
                      
                    } 
                  })
                  // D:\Santhosh\Projects\aes-sailor-on-boarding\backend\app\uploadedImages
                  .catch(error => {
                    // handle the error
                    console.error(error);
                    if (error) {
                      
                      toast.error("Something went wrong. Please try again Later....", {
                      position: toast.POSITION.TOP_CENTER,
                      theme: "colored",
                    });
            
                    }
                  
                  });

   
    
  }

  const downloadDocument = async(onboardingId) => {

    await axios.get(config.mongo_endpoint+"api/v1/sailorUser/downloadfiles/user_"+onboardingId, {
        headers: {"Content-type": "application/json",} ,
          responseType: 'blob',
    })
    .then(function (response) {
        console.log("sailor",response);
        const blob = new Blob([response.data], { type: 'application/zip' });
        const blobUrl = URL.createObjectURL(blob);
  
        // Create a temporary link and click it to trigger the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'Sailor Documents.zip'; // Set the desired file name
        link.click();
  
        // Clean up the blob URL
        URL.revokeObjectURL(blobUrl);
        toast.success("Documents Downloaded Successfully.....", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          });  
    })
    .catch(function (error) {
        console.log(error);
    });

}

  const [data , setData] = useState([])
    
  const findHistory = async() => {
    // let response = new FormData();viewPhotoByOnBoardingID
    // response.append('onBoardingID', '2');
      const response = {'onBoardingID': sailor_info.onBoardingID}
 

      await axios.post(config.mongo_endpoint+"api/v1/sailorUser/sailorHistory/findByOnboardingID", response, { headers: {  'Content-Type': 'application/x-www-form-urlencoded'} })
                  .then(result => {
                    console.log("fetch",result);
                    if(result.status == 200){
                      setData(result.data.data)
                    } 
                  })
                  .catch(error => {
                    // handle the error
                    console.error(error);
                    if (error) {
                      
                      toast.error("Something went wrong. Please try again Later....", {
                      position: toast.POSITION.TOP_CENTER,
                      theme: "colored",
                    });
            
                    }
                  
                  });
      
  }

  const handleDateChange = () => {
    
      const date = new Date();
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-');
      return formattedDate;
    
   
  };
  const onSubmitInfo = async() => {
    
    let updatedBy = localStorage.getItem('name');

    // Remove surrounding quotes (if present)
    updatedBy = updatedBy ? updatedBy.replace(/^"|"$/g, '') : '';
    console.log(updatedBy);
    
   const  request = {
    "Email":sailor_info.Email,
    "Application_Status":applicationStatus.status,
    "Update_Date": handleDateChange(),
    "Updated_By":`${localStorage.getItem('name')}`,
    "Commands":applicationStatus.comments,
    //"Application_Status_UpdatedAt" :handleDateChange(),
    //"Application_Status_UpdatedBy" : updatedBy,
    
  }
  if (applicationStatus.status === 'Approved') {
    request["Application_Status_UpdatedAt"] = handleDateChange();
    request["Application_Status_UpdatedBy"] = updatedBy;
  }
  

    try {
  
      await axios.post(config.mongo_endpoint+'api/v1/sailorUser/updatestatus', request, { headers: {  'Content-Type': 'application/x-www-form-urlencoded'} })
                  .then(result => {
                    console.log("fetch",result);
                    if(result.status == 200){
                     
                      toast.success("Status Updated Successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      });  
                      
                      setTimeout(function() { navigate(-1); }, 3000);
                    } else {
                     
                      toast.success("Status Update Failed", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      });  

                    }
                    
                    
                  })
                  .catch(error => {
                    // handle the error
                    console.error(error);
                    if (error) {
                      
                      toast.error("Something went wrong. Please try again Later....", {
                      position: toast.POSITION.TOP_CENTER,
                      theme: "colored",
                    });
            
                    }
                  
                  });


  } catch (error) {
    console.error(error);
    if (error) {
      alert("Something went wrong. Please try again Later....")
    }
  }

  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,

    },
  }));



  const [open, setOpen] = useState(false);
  const [selectedSailor, setSelectedSailor] = useState(null);
  const [newSailor, setNewSailor] = useState({
    sailorFrom: '',
    sailorTo: '',
    shipType: '',
    IMoNumber: '',
    endDate: '',
    availability: '',
  });

  const handleOpen = () => {
    setSelectedSailor(null); // Reset the selected sailor
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSailor(null);
    setNewSailor({
      sailorFrom: '',
      sailorTo: '',
      shipType: '',
      IMoNumber: '',
      endDate: '',
      startDate:'',
      availability: '',
    });
  };

  const handleChange = (event) => {
    setNewSailor({
      ...newSailor,
      [event.target.name]: event.target.value,
    });
  };

  // const handleSave = () => {
  //   // Add your save logic here (e.g., sending newSailor to backend or updating state)

  //   console.log('New Sailor added:', newSailor);
    
  //   handleClose();
  // };
  const handleDateFormatChange = (formdate) => {
    if (formdate) {
      const date = new Date(formdate);
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-');
      return formattedDate;
    }
   
  };

  const handleSave = async () => {
    if (!newSailor["sailorFrom"] || !newSailor["sailorTo"] || !newSailor["shipType"] || !newSailor["startDate"] || !newSailor["endDate"] || !newSailor['availability']) {
      toast.error("Please fill in all required fields before submitting.", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
      return; // Prevent submission if validation fails
    }
  
  console.log(sailor_info.onBoardingID)
   
    const sailor_editinfo = {
      
      sailorFrom: newSailor["sailorFrom"],
      sailorTo: newSailor["sailorTo"],
      shipType: newSailor["shipType"],
      IMoNumber: newSailor["IMoNumber"],
      startDate: handleDateFormatChange(newSailor['startDate']),
      endDate: handleDateFormatChange(newSailor['endDate']),
      status: newSailor['availability'],
      availability: newSailor['availability'],
      userId: sailor_info._id,
      
    };
  
    // Log to console for debugging
    console.log('New Sailor added:', newSailor);
    console.log(sailor_editinfo);
  
    try {
      // Make API request to update sailor history
      const result = await axios.post(
       config.mongo_endpoint + 'api/v1/sailorUser/sailorHistory/create',
        sailor_editinfo,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-access-token': localStorage.getItem('AgentToken'),
          },
        }
      );
  
      // Check the result and show appropriate toast messages
      if (result.status === 200 && result.data.status === "Success") {
        toast.success("Sailor History Updated Successfully.", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
        });
       
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
        });
      }
    } catch (error) {
      // Handle error
      console.error(error);
      toast.error("Something went wrong. Please try again later.", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    }
  
    // Close modal or form (assuming handleClose is a function that closes the form/modal)
    handleClose();
  };
  
  

  
  return (
    <>

      <AgentMenu></AgentMenu>

      <div class="container-fluid pr-0" id="main-area">
        <Navbar/>
        <div className="container-fluid">
          <div class="d-flex border-bottom pb-15 pt-15">
            <div class="me-auto ">
              <h2 class="page-title">View onboard</h2>
             
            </div>

          </div>
          <section className="voting-section">
            <div className="container">
              <div className="row">
                <div className="">
                <Box mb={3.5}>
                    <Grid container spacing={3}>
                      <Grid item sm={6} xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Personal Information</StyledTableCell>
                                {/* <StyledTableCell align="right"></StyledTableCell> */}
                                <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow sx={{ height: '50%' }}>
                                  <StyledTableCell component="th" scope="row">
                                    First Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.First_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Middle Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.middle_name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Last Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Last_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Date of Birth
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Date_of_Birth}
                                  </Moment>  : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Place of Birth
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Place_of_Birth : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Marital Status
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Maritial_Status : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Country
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Country : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Nationality
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Nationality : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Mobile No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Phone : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Address of Communication
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Corresponding_Address : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Permanent Address
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Permanent_Address : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Emergency Contact Person
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Emergency_Contact_Person : ""}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Emergency Contact Number
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Emergency_Contact_Number : ""}</StyledTableCell>

                                </StyledTableRow>

                              </TableBody>
                          </Table>
                        </TableContainer>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Passport Info</StyledTableCell>
                                <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Passport No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Passport_Number : ""} </StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Place of Issue
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Place_Of_Issue : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Date Of Issue
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Date_Of_Issue}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                  Valid Through
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Passport_Valid_Through ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Passport_Valid_Through}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>
                                
                              </TableBody>
                          </Table>
                        </TableContainer>
                        <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Bank Details</StyledTableCell>
                                  <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account Holder Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Account_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Account_Number : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    IFSC Code
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.IFSC_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    SWIFT Code
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.SWIFT_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Branch Address
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Branch_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        
                        
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Experience Details</StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                            Position Held
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info ? sailor_info.Last_Position_held : ""}</StyledTableCell>

                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Year of Experience at last held
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info ? sailor_info.Years_Of_Experience : ""}</StyledTableCell>

                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                            Position Applying For
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info.Position_Applying_for ? sailor_info.Position_Applying_for : ""}</StyledTableCell>

                          </StyledTableRow>

                          </TableBody>
                          </Table>
                        </TableContainer>
                         <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Documents</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right" style={{ padding: '10px', width: '200px' }}>
                                    <div style={{ align: "center", cursor: "pointer", alignItems: "center", display: "flex", justifyContent: "center", height: "100%" }}>
                                      <a onClick={() => { downloadDocument(sailor_info.onBoardingID) }}>Download Documents</a>
                                    </div>
                                  </StyledTableCell>
                                </TableRow>

                              </TableHead>
                              <TableBody>
                                
                                 {sailor_certificateinfo.map((resp, index) => (
                                

                                  <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                      {resp.Course_Name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right"> {resp.Issusing_Authority}</StyledTableCell>
                                    <StyledTableCell align="right"> {resp.Certificate_Valid_Till}</StyledTableCell>
                                    <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>

                                  </StyledTableRow>
                                  
                                 ))}  
                                 
                              </TableBody>
                            </Table>
                            {/* <div style={{align:"center",cursor: "pointer",alignItems: "center", display: "flex", justifyContent: "center"}}><a onClick={()=>{downloadDocument(sailor_info.onBoardingID)}} >Download Documents</a></div> */}
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell colSpan={3}>Verification Status</StyledTableCell>

                              </TableRow>

                            </TableHead>
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  <img src={img} style={{ width: '100px' }} />
                                </StyledTableCell>
                                <StyledTableCell align="center"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                                {/* <StyledTableCell align="right"> <img src={img} style={{ width: '100px' }} /></StyledTableCell> */}

                              </StyledTableRow>

                              {/* <StyledTableRow>
                                <StyledTableCell align="center" colSpan={3}><Alert severity="success" className="alert-message" sx={{ fontSize: "18px", width: "100%", color: "#2e7d32", border: "red" }}>Face Match : {sailor_info.face_match_per}%   <br/> <br/> Passport Match: {sailor_info.passport_match_per}%  </Alert></StyledTableCell>
                              </StyledTableRow> */}

<StyledTableRow>
                                <StyledTableCell align="center" colSpan={3}>
                                  {/* Face Match Alert */}
                                  <Alert
                                    severity="success"
                                    className="alert-message"
                                    sx={{
                                      fontSize: "18px",
                                      width: "100%",
                                      color: "#2e7d32",
                                      border: "1px solid #2e7d32",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      marginBottom: "12px",
                                      backgroundColor: "#e8f5e9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <strong>Face Match:</strong> {sailor_info.face_match_per}%
                                    </div>
                                  </Alert>

                                  {/* Passport Match Alert */}
                                  <Alert
                                    severity="success"
                                    className="alert-message"
                                    sx={{
                                      fontSize: "18px",
                                      width: "100%",
                                      color: "#2e7d32",
                                      border: "1px solid #2e7d32",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      marginBottom: "12px",
                                      backgroundColor: "#e8f5e9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                     <div style={{ display: "flex", alignItems: "center" }}>
                                      <strong>Passport Match Successful:</strong> {sailor_info.passport_match_per}%
                                    </div>
                                    <div style={{ marginTop: "8px", textAlign: "left" }}>
                                      <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
                                        <li style={{ marginBottom: "4px" }}>•  Name: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Passport Number: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Validity: Confirmed</li>
                                      </ul>
                                    </div>
                                  </Alert>

                                  {/* Penny Check Match Alert */}
                                  <Alert
                                    severity="success"
                                    className="alert-message"
                                    sx={{
                                      fontSize: "18px",
                                      width: "100%",
                                      color: "#2e7d32",
                                      border: "1px solid #2e7d32",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      marginBottom: "12px",
                                      backgroundColor: "#e8f5e9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                          <strong>Bank Account Match Successful</strong>
                                    </div>
                                    <div style={{ marginTop: "8px", textAlign: "left" }}>
                                      <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
                                        <li style={{ marginBottom: "4px" }}>•  Name: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Account Number: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Penny Drop Verfication: Completed</li>
                                        <li style={{ marginBottom: "4px" }}>•  Cancelled Cheque: Verified</li>
                                      </ul>
                                    </div>
                                  </Alert>

                                  {/* Document Check and Certificate Number Check Alert */}
                                  <Alert
                                    severity="success"
                                    className="alert-message"
                                    sx={{
                                      fontSize: "18px",
                                      width: "100%",
                                      color: "#2e7d32",
                                      border: "1px solid #2e7d32",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      backgroundColor: "#e8f5e9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <strong>Document Check Successful</strong>
                                    </div>
                                    <div style={{ marginTop: "8px", textAlign: "left" }}>
                                      <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
                                        <li style={{ marginBottom: "4px" }}>•  Document Validity: Verified</li>
                                        <li style={{ marginBottom: "4px" }}>•  Certificate Number: Verified</li>
                                      </ul>
                                    </div>
                                  </Alert>
                                </StyledTableCell>
                              </StyledTableRow>

                            </TableBody>
                          </Table>
                        </TableContainer>




                      </Grid>
                      {/* {data.length > 0 ? (
                        <>
                      <Grid item sm={12} xs={12} style={{ marginBottom: '10px' }}>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Sailor History
                      </Typography>
                    </Grid>
                      <Grid item sm={12} xs={12}>
                        <TableContainer>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ background: '#ddd' }}>S.No</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Onboarding ID</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Sailor From</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Sailor To</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Ship Type</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>IMo No</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>End Date</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Status</TableCell>




                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.map((row) => (
                                    <TableRow
                                        key={row.serialNumber}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" style={{ padding: '5px 15px' }}>
                                            {row.serialNumber}
                                        </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.onBoardingID}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.sailorFrom}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.sailorTo}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.shipType}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.IMoNumber}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{<Moment format="DD/MM/YYYY">
                                  {row.endDate}
                                  </Moment>}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.availability}</TableCell>
                                    </TableRow>
                                ))}
                            
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      </>
                      ) : ""} */}
                      {data.length > 0 ? (
                        <>
                            
                          <Grid 
                            item
                            sm={12}
                            xs={12}
                          >
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                              style={{
                                padding: '5px',
                              }}
                            >
                              <Grid item sm={8} xs={12}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                  Sailor History
                                </Typography>
                              </Grid>
                              {ScreenView === 1 && (
                                <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpen}
                                    style={{ marginTop: '10px' }}
                                  >
                                    Add Sailor History
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                            <TableContainer>
                              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ background: '#ddd' }}>S.No</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>
                                      Onboarding ID
                                    </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>
                                      Sailor From
                                    </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>
                                      Sailor To
                                    </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>
                                      Ship Type
                                    </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>
                                      IMo No
                                    </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>
                                      End Date
                                    </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>
                                      Status
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.map((row, index) => (
                                    <TableRow
                                      key={row.serialNumber}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell component="th" scope="row" style={{ padding: '5px 15px' }}>
                                        {row.serialNumber}
                                      </TableCell>
                                      <TableCell align="center" style={{ padding: '5px 15px' }}>
                                        {row.onBoardingID}
                                      </TableCell>
                                      <TableCell align="center" style={{ padding: '5px 15px' }}>
                                        {row.sailorFrom}
                                      </TableCell>
                                      <TableCell align="center" style={{ padding: '5px 15px' }}>
                                        {row.sailorTo}
                                      </TableCell>
                                      <TableCell align="center" style={{ padding: '5px 15px' }}>
                                        {row.shipType}
                                      </TableCell>
                                      <TableCell align="center" style={{ padding: '5px 15px' }}>
                                        {row.IMoNumber}
                                      </TableCell>
                                      <TableCell align="center" style={{ padding: '5px 15px' }}>
                                        <Moment format="DD/MM/YYYY">{row.endDate}</Moment>
                                      </TableCell>
                                      <TableCell align="center" style={{ padding: '5px 15px' }}>
                                        {row.availability}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                      { ScreenView === 2 && (<>
                      <Grid item sm={5} xs={12}>
                        <TextField fullWidth
                          id="outlined-textarea"
                          label="Add comments"
                          name="comments"
                          multiline
                          rows={4}
                           onChange={handleApplicationChange}
                          placeholder="Placeholder"

                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            // value={sailor_info ? sailor_info.Application_Status : ""}
                            label="Status"
                            onChange={handleApplicationChange}
                          >
                            <MenuItem value={"Approved"}>Approve</MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"Hold"}>Hold</MenuItem>
                            <MenuItem value={"Reject"}>Reject </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={2} xs={12}><Button variant="contained" fullWidth color="success" onClick={() => onSubmitInfo() } className="float-end">
                        Submit
                      </Button></Grid></>)}
                      {/* {JSON.stringify(applicationStatus)} */}
                      
                    </Grid>
                     

                  </Box>

                </div>

                <ToastContainer />
              </div>
            </div>
          </section>
        </div>
      </div>



      <Modal open={open} onClose={handleClose}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { xs: '90%', sm: '70%', md: '50%' },
      backgroundColor: 'white',
      borderRadius: 2,
      boxShadow: 24,
      p: 3,
      maxHeight: '90vh',
      overflowY: 'auto',
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: 'bold',
        marginBottom: 2,
        textAlign: 'center',
        color: 'primary.main',
      }}
    >
      Add New Sailor History
    </Typography>

    <Grid container spacing={2}>
      {/* Sailor Port From */}
      <Grid item sm={6} xs={12}>
        <div className="search_ship_port">
        <SearchBox
  
  accessToken="pk.eyJ1Ijoia2FydGhpY3JlYXRvciIsImEiOiJjbHRjaDkwa20yM3JsMmtxcjc5Y3Rqc3NwIn0.4QgS7mxhfIDVjvwwctqBLg"
  placeholder="Sailor Port From *"     
  name="sailorFrom"  // match the name prop here with the state key
  value={newSailor.sailorFrom}  // ensure this value is controlled by state
  onRetrieve={(result) => {
    if (result && result?.features?.[0]?.properties?.name) {
      handleChange({
        target: { name: "sailorFrom", value: result?.features?.[0]?.properties?.name },
      });
    }
  }}
/>
        </div>
      </Grid>

      {/* Sailor Port To */}
      <Grid item sm={6} xs={12}>
        <div className="search_ship_port">
          <SearchBox
            accessToken="pk.eyJ1Ijoia2FydGhpY3JlYXRvciIsImEiOiJjbHRjaDkwa20yM3JsMmtxcjc5Y3Rqc3NwIn0.4QgS7mxhfIDVjvwwctqBLg"
            placeholder="Sailor Port To *"
            name="sailorTo"
            value={newSailor.sailorTo}  
            onRetrieve={(result) => {
              if (result && result?.features?.[0]?.properties?.name) {
                handleChange({
                  target: { name: "sailorTo", value: result?.features?.[0]?.properties?.name }
                });
              }
            }}
          />
        </div>
      </Grid>

      {/* Ship Type */}
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Ship Type *</InputLabel>
          <Select
            label="Ship Type *"
            name="shipType"
            value={newSailor.shipType}
            onChange={handleChange}
          >
            <MenuItem value="Cargo">Cargo</MenuItem>
            <MenuItem value="Passenger">Passenger</MenuItem>
            <MenuItem value="Fishing">Fishing</MenuItem>
            <MenuItem value="Tanker">Tanker</MenuItem>
            <MenuItem value="Container">Container</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* IMO Number */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="IMO Number *"
          name="IMoNumber"
          value={newSailor.IMoNumber}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

      {/* Start Date */}
      <Grid item sm={4} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date *"
            value={newSailor.startDate}
            onChange={(date) => handleChange({
              target: { name: "startDate", value: date }
            })}
            fullWidth
          />
        </LocalizationProvider>
      </Grid>

      {/* End Date */}
      <Grid item sm={4} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End Date *"
            value={newSailor.endDate}
            onChange={(date) => handleChange({
              target: { name: "endDate", value: date }
            })}
            fullWidth
          />
        </LocalizationProvider>
      </Grid>

      {/* Status */}
      <Grid item sm={4} xs={12}>
        <FormControl fullWidth>
          <InputLabel>Status *</InputLabel>
          <Select
            label="Status *"
            name="availability"
            value={newSailor.availability}
            onChange={handleChange}
          >
            <MenuItem value="Available">Available</MenuItem>
            <MenuItem value="On Duty">On Duty</MenuItem>
            <MenuItem value="On Leave">On Leave</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Save Button */}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{
            width: '100%',
            padding: 1.5,
            marginTop: 2,
            fontWeight: 'bold',
          }}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  </Box>
</Modal>


    </>
  );
}

export default Viewonboard;