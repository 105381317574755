import React, { useState, useEffect,useContext } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';


function ManageCompany() {
    const obj = useContext(NoteContext);
    const [data, setData] = useState([]);

   
    const getCompanyList = async () => {
      let companyData = await fetch(config.mongo_endpoint+"api/v1/company/getall", {
        method: "GET",
        headers: {                       
            "x-access-token" : obj.AgentToken,            
            // "x-access-token" : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2NGRhMjA1NWVlOWQyYzIxNTBhMWQyMzkiLCJpYXQiOjE2OTc2MTk2MjR9.AywLCafWb2JxXVUfDcwAaewGxQ9wFXj_-kUG2lyNz08"
        }
      });
     
      let resultCompanyData = await companyData.json();
      if(resultCompanyData.status === 'Success'){
        const companylist = resultCompanyData.data;
        setData(companylist);
      }
      console.log(resultCompanyData)
    }

    useEffect(()=>{
        getCompanyList();     
    },[])
   

    const deleteCompany = async (companyCode) =>{
        console.log(companyCode)
        const companydatas = {
                    companyCode : companyCode,              
                }
                console.log(companydatas)
          swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this imaginary file!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                   axios.post(config.mongo_endpoint+"api/v1/company/delete", companydatas, {
                      headers: {"Content-type": "application/x-www-form-urlencoded", "x-access-token":obj.AgentToken} ,                   
                  })
                  getCompanyList(); 
                  swal("Company Name Deleted....!", {
                      icon: "success",
                    });
                    
              } else {
                  swal("Cancelled");
              }              
            }); 
                    
      } 
      
      async function editCompany(resdata){
        console.log("ROWEDIT**", resdata)
        obj.setUpdate_company(resdata)
        obj.setUpdatingCompanyFlag(1);
      }

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">
                      
                <Navbar />
                
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Manage Company</h2>
                        </div>
                        <div>
                        <Link to="/AddCompany" className="nav-link" >
                            <Button variant="contained" color="success">
  Add Company
</Button></Link></div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>                                    
                                    <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Company Name </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Company Code</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Country</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>State</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>City</TableCell> 
                                    <TableCell align="center" style={{ background: '#ddd' }}>Address</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>                       
                                </TableRow>
                            </TableHead>
                            <TableBody>                                                               
                              {data.map((resdata,index)=>(
                                    <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.company_name}</TableCell>
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.company_code}</TableCell>
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.country}</TableCell>
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.state}</TableCell>                                       
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.city}</TableCell>
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.address}</TableCell>          
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>   <DeleteIcon onClick={()=>deleteCompany(resdata.company_code)} /> 
                                    {/* <EditIcon onClick={() => {editAgent()}} /> */}
                                    <Link to="/UpdateCompany" onClick={()=>{editCompany(resdata)}}><EditIcon /></Link>
                                    <VisibilityIcon></VisibilityIcon>                           
                     
                                </TableCell>
                            </TableRow>
                              ))}                                                                                       
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                </div>
          





        </>
    );
}

export default ManageCompany;