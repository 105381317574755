import React, { useState, useEffect } from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, Title, PointElement, LineElement } from "chart.js";
import { Grid, Card, CardContent, Typography,ToggleButton, ToggleButtonGroup,Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import Navbar from "../Navbar";
import Dash1 from "../../assets/images/sailor/dash-ico-1.svg";
import Dash2 from "../../assets/images/sailor/dash-ico-2.svg";
import Dash3 from "../../assets/images/sailor/dash-ico-3.svg";
import Dash4 from "../../assets/images/sailor/dash-ico-4.svg";
import { config } from "../../util/apiconfig";


ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, Title, PointElement, LineElement);

function ManningAgentDashboard() {
    const [cardsData, setCardsData] = useState([]);
    const [pieChartData, setPieChartData] = useState({});
    const [rows, setRows] = useState([]);
    const [viewMode, setViewMode] = useState('table');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const [registrationData, setRegistrationData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.mongo_endpoint+"api/v1/manningAgent/viewdashboard", {
                    method: "GET",
                    headers: {
                    "Content-type": "application/json",
                    "x-access-token" : `${localStorage.getItem('AgentToken')}`
                    }           
                });
                
                const data = await response.json();

                console.log('data > ',data)

                // Process data and set state
                setCardsData(data.cardsData || []);  // Ensure cardsData is always an array
                setPieChartData(data.pieChartData || {});  // Ensure pieChartData is always an object
                setRows(data.rows || []);  // Ensure rows is always an array
                setRegistrationData(data.dailyRegistrations || []);

                console.log('registrationData labels > ',data.dailyRegistrations)
                console.log('registrationData datasets > ',registrationData.datasets)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // const cardsData = [
    //     {
    //         label: "Total No Registration",
    //         value: 1,
    //         icon: Dash1,
    //         bgColor: "#ffffff",
    //     },
    //     {
    //         label: "Today Registrations",
    //         value: 2,
    //         icon: Dash2,
    //         bgColor: "#ffffff",
    //     },
    //     {
    //         label: "Approved Onboarding",
    //         value: 3,
    //         icon: Dash3,
    //         bgColor: "#ffffff",
    //     },
    //     {
    //         label: "Pending Onboarding",
    //         value: 4,
    //         icon: Dash3,
    //         bgColor: "#ffffff",
    //     },
    //     {
    //         label: "Hold",
    //         value: 5,
    //         icon: Dash3,
    //         bgColor: "#ffffff",
    //     },
    //     {
    //         label: "Total Rejected",
    //         value: 6,
    //         icon: Dash4,
    //         bgColor: "#ffffff",
    //     },
    // ];

    // const pieChartData = {
    //     labels: ["Approved", "Pending", "Rejected"],
    //     datasets: [
    //         {
    //             data: [data.approvedCount, data.pendingCount, data.rejectCount],
    //             backgroundColor: ["#4caf50", "#ff9800", "#f44336"],
    //         },
    //     ],
    // };

    // const rows = [
    //     { position: "Chief Engineer", available: 11, onDuty: 12, onLeave: 16 },
    //     { position: "Captain (Master)", available: 5, onDuty: 7, onLeave: 3 },
    //     { position: "Second Engineer", available: 17, onDuty: 20, onLeave: 10 },
    //     { position: "Deck Officer", available: 8, onDuty: 9, onLeave: 5 },
    //     { position: "Third Engineer", available: 6, onDuty: 4, onLeave: 2 },
    //     { position: "Electrician", available: 7, onDuty: 6, onLeave: 3 },
    //     { position: "Mechanic", available: 9, onDuty: 8, onLeave: 4 },
    // ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const getIcon = (iconName) => {
        switch (iconName) {
          case "Dash1":
            return Dash1;
          case "Dash2":
            return Dash2;
          case "Dash3":
            return Dash3;
          case "Dash4":
            return Dash4;
          default:
            return null; // Fallback to no icon or a default icon if the value doesn't match
        }
      };

      // Prepare the data for the chart
    const chartData = {
        labels: registrationData.labels || [], // Days of the week (Mon, Tue, Wed, etc.)
        datasets: registrationData.datasets || [], // Registration data
    };


    return (
        <>
            <AgentMenu />
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-3 pt-3">
                        <div className="me-auto">
                            <h2 className="page-title">Agent Dashboard</h2>
                        </div>
                    </div>
                    
                    {/* Cards Section */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                {cardsData && cardsData.length > 0 && cardsData.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={6} key={index}>
                                        <Card style={{ border: "none" }}>
                                            <CardContent>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={8}>
                                                        <Typography variant="body2" style={{ fontWeight: "bold", marginBottom: "8px" }}>
                                                            {card.label}
                                                        </Typography>
                                                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                                            {card.value}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <div
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                backgroundColor: card.bgColor,
                                                                borderRadius: "50%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <img src={getIcon(card.icon)} alt={card.label} style={{ width: "30px" }} />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Daily Registrations Overview
                                    </Typography>
                                    <div style={{ height: "300px" }}>
                                        <Line
                                            data={chartData}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                scales: {
                                                    y: {
                                                        beginAtZero: true,
                                                        ticks: {
                                                            callback: function (value, index, values) {
                                                                return Number.isInteger(value) ? value : null; // Show only integer values
                                                            },
                                                            stepSize: 1, // Ensure the step size is consistent to avoid duplicates
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {/* Chart & Trend Section */}
                    <Grid container spacing={3} style={{ marginTop: "20px" }}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Sailors Status
                                    </Typography>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ height: "300px", width: "60%" }}>
                                        {pieChartData?.labels?.length > 0 && pieChartData?.datasets?.[0]?.data?.length > 0 && (
                                            <Pie
                                                data={pieChartData}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                        </div>
                                        <div style={{ width: "35%", marginLeft: "20px" }}>
                                            <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "left" }}>
                                                <tbody>
                                                    {pieChartData.labels?.map((label, index) => (
                                                        <tr key={index}>
                                                            <td style={{ padding: "8px", display: "flex", alignItems: "center" }}>
                                                                <span
                                                                    style={{
                                                                        display: "inline-block",
                                                                        width: "12px",
                                                                        height: "12px",
                                                                        backgroundColor: pieChartData.datasets[0]?.backgroundColor[index],
                                                                        borderRadius: "50%",
                                                                        marginRight: "8px",
                                                                    }}
                                                                ></span>
                                                                {label}
                                                            </td>
                                                            <td style={{ padding: "8px" }}>
                                                                {pieChartData.datasets[0]?.data[index]}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        
                        {/* Sailors Positions Details */}
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Sailors Positions Details
                                    </Typography>
                                    <ToggleButtonGroup
                                        value={viewMode}
                                        exclusive
                                        onChange={(e, value) => setViewMode(value || viewMode)}
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <ToggleButton value="table">Table View</ToggleButton>
                                        <ToggleButton value="chart">Chart View</ToggleButton>
                                    </ToggleButtonGroup>
                                    {viewMode === "table" ? (
                                        <div style={{ height: "300px", display: "flex", flexDirection: "column" }}>
                                            <TableContainer style={{ flexGrow: 1 }}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: "bold" }}>Position</TableCell>
                                                            <TableCell style={{ fontWeight: "bold" }}>Available</TableCell>
                                                            <TableCell style={{ fontWeight: "bold" }}>On Duty</TableCell>
                                                            <TableCell style={{ fontWeight: "bold" }}>On Leave</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {rows && Array.isArray(rows) && rows.length > 0 ? (
                                                            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{row.position}</TableCell>
                                                                    <TableCell>{row.available}</TableCell>
                                                                    <TableCell>{row.onDuty}</TableCell>
                                                                    <TableCell>{row.onLeave}</TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell colSpan={4} style={{ textAlign: "center" }}>No Data Available</TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 15]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </div>
                                    ) : (
                                        <div style={{ height: "300px" }}>
                                            <Bar
                                                data={{
                                                    labels: rows.map((row) => row.position),
                                                    datasets: [
                                                        {
                                                            label: "Available",
                                                            data: rows.map((row) => row.available),
                                                            backgroundColor: "#32CD32",
                                                        },
                                                        {
                                                            label: "On Duty",
                                                            data: rows.map((row) => row.onDuty),
                                                            backgroundColor: "#FFD700",
                                                        },
                                                        {
                                                            label: "On Leave",
                                                            data: rows.map((row) => row.onLeave),
                                                            backgroundColor: "#FF4500",
                                                        },
                                                    ],
                                                }}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    plugins: {
                                                        legend: {
                                                            position: "top",
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default ManningAgentDashboard;
